<template>
  <BlokkliProvider
    v-slot="{ entity, isEditing }"
    v-bind="blokkliProps"
    :entity="props"
  >
    <div>
      <template v-if="title">
        <ContentFullscreenHeader
          v-if="hasFullscreenHeader && mainImage"
          :title="entity?.title || title"
          :media="entity?.mainImage || mainImage"
          :lead="entity?.lead || lead"
        />
        <ContentHeader
          v-else-if="title"
          :title="entity?.title || title"
          :lead="entity?.lead || lead"
          :media="entity?.mainImage || mainImage"
          :easy-to-read-url="easyToReadUrl?.path"
        />
      </template>

      <BlokkliField
        v-slot="{ items }"
        :list="paragraphs"
        name="field_paragraphs"
      >
        <TableOfContents :paragraph-items="items" :is-editing="isEditing" />
      </BlokkliField>
    </div>
  </BlokkliProvider>

  <hr class="bg-green-500 h-100 border-none" />
</template>

<script lang="ts" setup>
import type { NodePageFragment } from '#graphql-operations'

const props = defineProps<{
  title?: string
  paragraphs?: NodePageFragment['paragraphs']
  easyToReadUrl?: NodePageFragment['easyToReadUrl']
  headerStyle?: string
  mainImage?: NodePageFragment['mainImage']
  lead?: NodePageFragment['lead']
  blokkliProps: NodePageFragment['blokkliProps']
}>()

const hasFullscreenHeader = computed(() => props.headerStyle === 'fullscreen')
</script>
